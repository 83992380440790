import ScrollTopButton from '../buttons/scroll-top-button/ScrollTopButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import useBodyHasScroll from '../../hooks/useBodyHasScroll';
import { Box, Container, Divider, Grid, IconButton, Link, Typography } from '@mui/material';
import { FooterBox } from './Footer.styled';

const Footer = () => {
  const { hasScroll } = useBodyHasScroll();

  const footerSections = [
    {
      title: 'Para Vendedores',
      links: [
        { text: 'Empezar a vender', href: '/be-guru' },
      ],
    },
    {
      title: 'Soporte',
      links: [
        { text: 'Preguntas frecuentes', href: '/faqs' },
        { text: 'Contacto', href: 'mailto:contacto@gurapp.cl' },
      ],
    },
    {
      title: 'Legal',
      links: [
        { text: 'Términos y condiciones', href: '/terms-and-conditions' },
      ],
    },
  ];

  const rrssSections = {
    title: 'Síguenos',
    links: [
      { text: 'Instagram', href: 'https://www.instagram.com/gurappcl', icon: InstagramIcon },
      { text: 'Facebook', href: 'https://www.facebook.com/gurappcl', icon: FacebookIcon },
    ],
  };

  const copyrightSection = `${String.fromCodePoint(169)} ${new Date().getFullYear()} GurApp. Todos los derechos reservados`;
  
  const columns = 12 / (footerSections.length + 1);
  return (
    <FooterBox component="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-evenly" mt={0.5}>
          {footerSections.map((section) => (
            <Grid item xs={12} sm={6} md={columns} key={section.title}>
              <Typography variant="h6" color="text.primary.contrastText" gutterBottom>
                {section.title}
              </Typography>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {section.links.map((link) => (
                  <li key={link.text}>
                    <Link href={link.href} variant="body2" color="inherit">
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={columns}>
            <Typography variant="h6" color="text.primary.contrastText" gutterBottom>
              {rrssSections.title}
            </Typography>
            <Box>
              {rrssSections.links.map((link) => (
                <IconButton key={link.text} aria-label={link.text} color="inherit" component={Link} href={link.href} target='_blank'>
                  {link.icon && <link.icon />}
                </IconButton>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body2">
              {copyrightSection}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      {hasScroll && <ScrollTopButton></ScrollTopButton>}
    </FooterBox>
  );
};

export default Footer;