import { createTheme } from '@mui/material/styles';

export const primaryMain = '#6501A3';
export const primaryLight = '#9840d5';
export const primaryDark = '#222144';
export const textOnWhite2 = '#757575';

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

const MuiTypographyGeneral = {
  // marginBottom: '8px',
  // lineHeight: 1.3,
  letterSpacing: 0.25,
  opacity: 0.90,
  whiteSpace: 'pre-line',
};
export default createTheme({
  palette: {
    primary: {
      light: primaryLight,
      main: primaryMain,
      dark: primaryDark,
      contrastText: '#fff',
    },
    secondary: {
      main: '#00929A',
      light: '#00B2B8',
      dark: '#00606B',
      contrastText: '#fff',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          height: '28px',
          fontWeight: 500,
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            ...MuiTypographyGeneral,
            fontWeight: 500,
            fontSize: '18px',
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            ...MuiTypographyGeneral,
            fontWeight: 300,
            fontSize: '16px',
            // marginBottom: '16px',
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontSize: '16px',
            ...MuiTypographyGeneral,
          },
        },
        // {
        //   props: { variant: 'h3' },
        //   style: {
        //     fontWeight: 500,
        //     fontSize: '16px',
        //     ...MuiTypographyGeneral,
        //   },
        // },
        // {
        //   props: { variant: 'h4' },
        //   style: {
        //     fontWeight: 400,
        //     fontSize: '16px',
        //     ...MuiTypographyGeneral,
        //   },
        // },
      ],
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-complete': {
            color: '#04919A',
          },
          '&.Mui-active': {
            color: '#04919A',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 300,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: primaryMain,
            color: '#fff',
            '& .MuiListItemIcon-root': {
              color: '#fff',
            },
            '&:hover': {
              backgroundColor: primaryLight,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          borderRadius: 50,
          paddingLeft: '24px',
          paddingRight: '24px',
          inlineSize: 'max-content',
        },
        outlined: {
          backgroundColor: 'white',
        },
      },
    },
  },
});
