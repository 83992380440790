import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import { Grid, Divider, styled, Container, useMediaQuery, useTheme, Rating } from '@mui/material';
import MainContainer from '../../containers/main-container/MainContainer';
import { NavigationBar } from '../../navigations';
import { KnowledgeEntity, PublicationEntity } from '../../../entities';
import { LinkButton } from '../../buttons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import getNumberFormatCurrency from '../../../utils/getNumberFormatCurrency';
import Section from './Section';
import Speaker from './Speaker';
import HorizontalImages from '../../images/horizontal-images/HorizontalImages';
import NewQuestion from '../question-answer/NewQuestion';
import Answers from '../question-answer/Answers';
import VerticalContent from './VerticalContent';
import Review from './Review';
import Paragraph from '../../typographies/Paragraph';
import PageTitle from '../../typographies/PageTitle';
import useAnalytics from '../../../hooks/useAnalytics/useAnalytics';
import IconInfoMode from './Icons/IconInfoMode';
import IconInfoQuota from './Icons/IconInfoQuota';
import IconInfoAgeranges from './Icons/IconInfoAgeranges';
import IconInfoDuration from './Icons/IconInfoDuration';

interface PublicationTemplateProps {
  publication?: PublicationEntity;
  children?: ReactNode;
  disabledActions?: boolean;
}

const RightContainer = styled(Container)(
  ({ isdownmd = 0 }: { isdownmd?: number }) => `
  border: 1px solid #000;
  overflow: auto;
  ${isdownmd === 0 && `
    border: 1px solid #000;
    overflow: auto;
  `};
`,
);

export const PublicationTemplate = forwardRef<HTMLDivElement, PublicationTemplateProps>(
  ({ publication, children, disabledActions, ...rest }, ref) => {
    const navigate = useNavigate();
    const { traceBtnClick } = useAnalytics();
    const location = useLocation();
    const { t } = useTranslation(['components'], { keyPrefix: 'publication-template' });
    const theme = useTheme();
    const isdownmd = useMediaQuery(theme.breakpoints.down('md'));

    const refLeftContainer = useRef<HTMLDivElement>(null);
    const [heightLeftContainer, setHeightLeftContainer] = useState<number>(0);
    useEffect(() => {
      if (refLeftContainer.current) {
        setHeightLeftContainer(refLeftContainer.current.clientHeight);
      }
    }, [refLeftContainer]);

    const contents = [{ 
      value: 'detail', 
      label: t('detail'), 
      description: publication?.detail ?? '', 
    }, { 
      value: 'material', 
      label: t('material'),
      description: publication?.material ?? '',
    }, { 
      value: 'knowledge', 
      label: t('knowledge.title'), 
      description: `${publication?.knowledges?.map((knowledge : KnowledgeEntity) => knowledge.name).join(', ')}${publication?.knowledgeDetail ? `\n${publication?.knowledgeDetail}` : ''}`,
    }];

    return (
      <>
        <MainContainer showFooter ref={ref} {...rest}>
          <NavigationBar variant="variant2" />
          <Container maxWidth="lg" disableGutters>
            {location.state?.from === '/publications' && (
              <LinkButton 
                onClick={() => { 
                  traceBtnClick({
                    step: 'step volver a la busqueda',
                    product: t('backSearch'),
                  });
                  navigate(-1);
                }} 
                color='black'
              >
                <Paragraph fontWeight={500}>
                  {t('backSearch')}
                </Paragraph>
              </LinkButton> 
            )}
            <HorizontalImages images={publication?.audiovisuals ?? []} />
            <Grid container columnSpacing={2} ref={refLeftContainer} mt={1.5}>
              <Grid container spacing={1.5} item xs={12} md={children ? 8 : 12} lg={children ? 9 : 12}>
                {!!publication?.mode && (
                  <Grid item xs={12} display={'flex'}>
                    <LocationOnOutlinedIcon />
                    <Paragraph fontWeight={500}>
                      {`${t(`mode.${publication?.mode}`)}${publication?.location ? ': ' + publication?.location?.description : ''}`}
                    </Paragraph>
                  </Grid>
                )}
                
                {!!publication?.modeDetail && (
                  <Grid item xs={12}>
                    <Paragraph>{publication?.modeDetail}</Paragraph>
                  </Grid>
                )}
               
                {!!publication?.title && (
                  <Grid item xs={12}>
                    <PageTitle fontSize={26}>{publication?.title}</PageTitle>
                  </Grid>
                )}
                
                {publication?.evaluationCount && (
                  <Grid item xs={12} display={'flex'}>
                    <Rating
                      value={publication?.evaluation}
                      precision={0.5}
                      readOnly
                      size="small"
                    />
                    <Paragraph fontSize={14} style={{ opacity: 0.6, marginLeft: 4 }}>
                      {` ${publication?.evaluationCount} reseña${publication?.evaluationCount > 1 ? 's' : ''}`} 
                    </Paragraph>
                  </Grid>
                )}
               
                {!!publication?.price && (
                  <Grid item xs={12}>
                    <Paragraph>{`Precio ${getNumberFormatCurrency(publication.price)}`}</Paragraph>
                  </Grid>
                )}

                {!!publication?.description && (
                  <Grid item xs={12}>
                    <Section 
                      title={t('description')}
                      description={publication?.description}
                    />
                  </Grid>
                )}

                <Grid item container xs={12} columnSpacing={2}>
                  <IconInfoMode publication={publication} />
                  {publication?.agenda === 'scheduled' && (
                    <IconInfoDuration publication={publication} />
                  )}
                  {publication?.agenda === 'scheduled' && (
                    <IconInfoQuota publication={publication} />
                  )}
                  <IconInfoAgeranges publication={publication} />
                </Grid>
              
                {/* <TabContent contents={contents} /> */}
                <VerticalContent contents={contents} />

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Speaker user={publication?.createdBy} />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                  
                {!!publication?.reviewsFormat?.length && (
                  <>
                    <Grid item xs={12}>
                      <Paragraph fontWeight={500}>
                        Reseñas
                      </Paragraph>
                    </Grid>
                    {publication.reviewsFormat.map((review) => (
                      <Grid item xs={12}>
                        <Review
                          key={review.id}
                          evaluation={review.evaluation}
                          comment={review.comment}
                          evaluatedAt={review.evaluatedAt}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <NewQuestion 
                    publicationId={publication?.id ?? 0}
                    disabled={disabledActions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Answers questionAnswers={publication?.questionAnswers} />
                </Grid>
              </Grid>

              {!!children && (
                <Grid item xs={12} md={4} lg={3} >
                  <RightContainer 
                    isdownmd={isdownmd ? 1 : 0} 
                    style={{ 
                      maxHeight: heightLeftContainer, 
                      position: 'sticky',
                      top: 16,
                    }}
                  >       
                    {children}
                  </RightContainer>
                </Grid>
              )}
            </Grid>
          </Container>
        </MainContainer>
      </>
    );
  });
